import { Icon } from '@chakra-ui/react'

import { IncidentType } from '@/graphql/generated/schemas'

import { Alarm } from './Alarm'
import { DeviceHealthIncident } from './DeviceHealthIncident'
import { DoorForced } from './DoorForced'
import { DoorHeldOpen } from './DoorHeldOpen'
import { LineCrossing } from './LineCrossing'
import { ManualIncident } from './ManualIncident'
import { TailgatingIncident } from './TailgatingIncident'

interface IncidentIconIProps {
  incidentType: IncidentType
  size?: number
}

export const IncidentIcon = ({
  incidentType,
  size = 50,
}: IncidentIconIProps) => {
  const iconStyleProps = {
    boxSize: `${size}px`,
    strokeColor: '#fff',
    backgroundColor: 'brand.100',
    bgGradient: 'linear(to-t, brand.100, brand.200)',
    padding: `${Math.floor(size / 10)}px`,
    borderRadius: 'base',
  }

  switch (incidentType) {
    case IncidentType.DeviceHealth:
      return <Icon as={DeviceHealthIncident} {...iconStyleProps} />
    case IncidentType.ForcedEntry:
      return <Icon as={DoorForced} {...iconStyleProps} />
    case IncidentType.Tailgating:
      return <Icon as={TailgatingIncident} {...iconStyleProps} />
    case IncidentType.DoorHeldOpen:
      return <Icon as={DoorHeldOpen} {...iconStyleProps} />
    case IncidentType.DoorHeldClose:
      return <Icon as={DoorHeldOpen} {...iconStyleProps} />
    case IncidentType.LineCrossing:
      return <Icon as={LineCrossing} {...iconStyleProps} />
    case IncidentType.Alarm:
      return <Icon as={Alarm} {...iconStyleProps} />
    default:
      return <Icon as={ManualIncident} {...iconStyleProps} />
  }
}
