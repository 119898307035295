export const Alarm = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => (
  <svg
    className={className}
    height='122.000000pt'
    preserveAspectRatio='xMidYMid meet'
    viewBox='0 0 130.000000 122.000000'
    width='130.000000pt'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      fill={strokeColor}
      stroke='none'
      transform='translate(0.000000,122.000000) scale(0.100000,-0.100000)'
    >
      <path d='M460 885 c0 -284 4 -304 61 -348 72 -54 175 -59 256 -12 75 45 78 54 81 353 l3 262 -200 0 -201 0 0 -255z m340 185 c0 -19 -7 -20 -145 -20 -138 0 -145 1 -145 20 0 19 7 20 145 20 138 0 145 -1 145 -20z m-32 -77 c-3 -16 -18 -18 -111 -21 -102 -3 -107 -2 -107 18 0 19 6 20 111 20 100 0 110 -2 107 -17z m15 -153 l37 -11 0 -100 c0 -94 -1 -101 -25 -123 -66 -61 -214 -59 -277 4 -27 27 -28 33 -28 124 l0 95 63 15 c66 16 164 15 230 -4z' />
      <path d='M573 808 l-43 -9 0 -72 c0 -40 4 -77 8 -83 26 -40 166 -53 216 -21 24 16 26 22 26 96 l0 79 -44 11 c-52 13 -104 13 -163 -1z' />
      <path d='M196 1037 c-31 -35 -56 -68 -56 -73 0 -5 59 -70 130 -144 l130 -135 -70 205 c-39 112 -72 206 -74 207 -2 2 -29 -25 -60 -60z' />
      <path d='M1052 1080 c-34 -91 -132 -383 -129 -386 2 -2 60 58 130 133 l126 137 -29 35 c-16 20 -43 52 -59 71 l-30 35 -9 -25z' />
      <path d='M120 721 c0 -44 -9 -40 128 -61 40 -7 75 -10 77 -8 2 2 -43 27 -100 56 l-105 52 0 -39z' />
      <path d='M1080 705 c-58 -30 -100 -54 -93 -54 26 -2 169 21 191 29 17 7 22 18 22 45 0 19 -3 35 -7 34 -5 0 -55 -24 -113 -54z' />
      <path d='M368 595 c-77 -51 -92 -120 -37 -175 41 -41 75 -57 166 -81 183 -46 417 -3 497 91 43 52 28 113 -37 157 -53 36 -64 37 -71 8 -10 -41 -73 -102 -124 -122 -126 -48 -272 -2 -322 103 -12 24 -25 44 -28 43 -4 0 -23 -11 -44 -24z' />
      <path d='M188 426 c-196 -150 14 -334 411 -363 222 -15 468 52 563 155 38 42 40 47 36 94 -4 39 -12 57 -39 84 -30 32 -78 64 -94 64 -3 0 -5 -32 -5 -70 0 -78 -12 -111 -57 -151 -44 -39 -74 -55 -149 -79 -175 -54 -401 -28 -523 60 -57 41 -71 75 -71 165 0 88 -6 91 -72 41z' />
      <path d='M300 349 c0 -168 433 -239 649 -107 41 26 71 73 71 115 l0 32 -57 -35 c-93 -56 -149 -69 -303 -69 -154 0 -244 21 -318 75 l-42 30 0 -41z' />
    </g>
  </svg>
)
