import { useKeyCloak } from '@/hooks/useKeyCloak'
import { useDispatch } from 'react-redux'

import { useActiveIncidentsFilterQuery } from '@/graphql/generated/hooks'
import {
  buildActiveFilter,
  buildActiveFilterCriteria,
} from '@/redux/incidents/conversion'
import {
  setActiveFilter,
  setActiveFilterCriteria,
} from '@/redux/incidents/incidentsSlice'

export const ActiveFilterProvider = ({
  children,
}: {
  children?: React.ReactNode
}) => {
  const dispatch = useDispatch()
  const { isValidLogin } = useKeyCloak()
  useActiveIncidentsFilterQuery({
    errorPolicy: 'all',
    skip: !isValidLogin,
    onCompleted: (data) => {
      dispatch(setActiveFilterCriteria(buildActiveFilterCriteria(data)))
      dispatch(setActiveFilter(buildActiveFilter(data)))
    },
    onError: () => {
      dispatch(setActiveFilter(null))
    },
  })

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
