import { ReactNode } from 'react'

import {
  Table as CharkraTable,
  TableContainer,
  TableContainerProps,
  TableProps,
} from '@chakra-ui/react'

export interface TableIProps {
  children: ReactNode
  tableContainerStyles?: TableContainerProps
  tableStyles?: TableProps
}

export const Table = ({
  children,
  tableContainerStyles,
  tableStyles,
}: TableIProps) => {
  return (
    <TableContainer
      border='1px solid #E0E0E0'
      borderRadius='20px'
      width='100%'
      {...tableContainerStyles}
    >
      <CharkraTable
        style={{ backgroundColor: 'white' }}
        transition='ease-in-out'
        transitionDuration='300ms'
        transitionProperty='all'
        width='100%'
        {...tableStyles}
      >
        {children}
      </CharkraTable>
    </TableContainer>
  )
}
