import { CaseActionType } from '@/graphql/generated/schemas'

import { isAIOperator } from './incidents'

export function removeUnderscoreAndCapitalize(value: string) {
  return value
    ?.replace(/_/g, ' ')
    .toLowerCase()
    .replace(/(^|\s)\w/g, (s) => s.toUpperCase())
}

export function formatTimeInterval(value: number): string {
  const SECONDS_IN_MINUTE = 60
  if (value < SECONDS_IN_MINUTE) {
    return `${value}s`
  }

  const minutes = Math.floor(value / SECONDS_IN_MINUTE)
  const seconds = value % SECONDS_IN_MINUTE
  return `${minutes}m ${seconds}s`
}

export const formatBytes = (bytes: number) => {
  if (bytes == 0) {
    return '0.00 B'
  }
  const newBytes = Math.floor(Math.log(bytes) / Math.log(1024))
  const newBytesStr = (bytes / Math.pow(1024, newBytes)).toFixed(2)
  const suffix = ' kMGTP'.charAt(newBytes) + 'B'
  return `${newBytesStr} ${suffix.trimStart()}`
}

export const getArchivedLabel = (label: string, isArchived: boolean) =>
  isArchived ? `${label} - ARCHIVED` : label

export const getArchivedLabelWithSN = (facility: {
  name?: string
  shortName?: string
  archived?: boolean
}) =>
  `${facility?.name} - (${facility?.shortName})${
    facility?.archived ? ' - ARCHIVED' : ''
  }`

export const getUserRoleSuffix = (userRole: string) => {
  switch (userRole) {
    case 'OPERATOR':
      return ' - (Operator)'
    case 'PROGRAM_MANAGER':
      return ' - (PM)'
    default:
      return ''
  }
}

export const getUserRoleDisplay = (userRole: string) => {
  switch (userRole) {
    case 'OPERATOR':
      return 'Operator'
    case 'PROGRAM_MANAGER':
      return 'PM'
    default:
      return ''
  }
}

export const getCaseActionString = (action: CaseActionType): string => {
  switch (action) {
    case CaseActionType.AddComment:
      return 'Added a Comment'
    case CaseActionType.AssignOwner:
      return 'Assigned An Owner'
    case CaseActionType.AssociateIncident:
      return 'Associated an Incident'
    case CaseActionType.DisassociateIncident:
      return 'Dissassociated an Incident'
    case CaseActionType.Open:
      return 'Opened the Case'
    case CaseActionType.Close:
      return 'Closed the Case'
    default:
      'Added a File'
  }
}

export function creatorRoleName(roleName: string) {
  switch (roleName) {
    case 'GUARD':
      return 'Guard'
    case 'PROGRAM_MANAGER':
      return 'PM'
    case 'IMPLEMENTER':
      return 'Implementer'
    default:
      return 'Operator'
  }
}

export function getAiOrCreatorRoleName(creator: {
  firstName?: string
  lastName?: string
  roleName?: string
}) {
  if (isAIOperator(creator)) {
    return ''
  } else {
    return creatorRoleName(creator.roleName)
  }
}

// Constructs a full name from an object with optional first and last names.
export const formatFullName = (obj: {
  firstName?: string
  lastName?: string
}) => {
  const hasFullName = !!obj?.firstName && !!obj?.lastName
  const firstName = obj?.firstName || ''
  const lastName = obj?.lastName || ''
  const separator = hasFullName ? ' ' : ''

  return `${firstName}${separator}${lastName}`
}
