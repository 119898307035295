import { useRouter } from 'next/compat/router'
import { useDispatch } from 'react-redux'

import { openFacilityFloorsDrawer } from '@/redux/ui/uiSlice'
import { FLOOR_PLAN } from '@/utils/routes'

export const useNavigation = () => {
  const { push } = useRouter()
  const dispatch = useDispatch()

  const goToFloorPlan = (floorId: string) => {
    dispatch(openFacilityFloorsDrawer())
    push({
      pathname: FLOOR_PLAN,
      query: { floorId: floorId },
    })
  }
  return { goToFloorPlan }
}
