import Keycloak from 'keycloak-js'
import { KeycloakConfig } from 'keycloak-js'

import { PERMITTED_USER_ROLES, Role } from '@/features/managed-accounts'

import { AuthClientInitOptionsI, ROLE_STATUS, TokenParsedI } from './types'

export const MIN_TOKEN_VALIDITY_IN_MINUTES = 5
export const TOKEN_UPDATE_TIMEOUT_IN_MILLISECONDS = 60000

export const getInitOptions = (): AuthClientInitOptionsI => {
  return { onLoad: '' }
}

export const getKeycloakInstance = (): Keycloak => {
  const keycloakConfig: KeycloakConfig = {
    url: process.env.NEXT_PUBLIC_KEYCLOAK_URL,
    clientId: 'public',
    realm: process.env.NEXT_PUBLIC_KEYCLOAK_REALM,
  }
  return new Keycloak(keycloakConfig)
}

export const parseJwt = (token: string): TokenParsedI => {
  try {
    const base64Url = token?.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )
    return JSON.parse(jsonPayload)
  } catch {
    return null
  }
}

export const isValidToken = (token: string): boolean => {
  if (!token) {
    return false
  }
  const tokenParsed = parseJwt(token)
  const expiresIn = tokenParsed?.exp - Math.ceil(new Date().getTime() / 1000)
  return expiresIn > 0
}

export const getRoleStatus = (token: string): ROLE_STATUS => {
  if (!token) {
    return ROLE_STATUS.NOT_SET
  }
  const { realm_access } = parseJwt(token) || {}
  const isValid = PERMITTED_USER_ROLES.includes(
    getRole(realm_access?.roles?.[0])
  )
  return isValid ? ROLE_STATUS.VALID : ROLE_STATUS.INVALID
}

export const getRole = (role: string): Role => {
  switch (role) {
    case 'ROLE_IMPLEMENTER':
      return Role.IMPLEMENTER
    case 'ROLE_PROGRAM_MANAGER':
      return Role.PROGRAM_MANAGER
    case 'ROLE_OPERATOR':
      return Role.OPERATOR
    case 'ROLE_GUARD':
      return Role.GUARD
    case 'ROLE_READ_ONLY_USER':
      return Role.READ_ONLY_USER
    default:
      return Role.UNKNOWN
  }
}
