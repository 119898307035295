import { ApolloQueryResult } from '@apollo/client'

import { CameraFeedQuery } from '@/graphql/generated/operations'
import { Exact } from '@/graphql/generated/schemas'

export interface IncidentVideoStateIProps {
  url: string
  isLoading: boolean
  errorMessage?: string
  isProcessing?: boolean
}

export interface DeviceFeedIProps {
  isDeviceDataLoading?: boolean
  stream: CameraStreamStateIProps
  nextCamera: () => void
  previousCamera: () => void
  availableCamerasIds: string[]
  errorMessage?: string
  hasCamera: boolean
}

export interface CameraStreamStateIProps {
  url: string
  isLoading: boolean
  errorMessage?: string
  refetch?: (
    variables?: Partial<
      Exact<{
        deviceId: string
      }>
    >
  ) => Promise<ApolloQueryResult<CameraFeedQuery>>
}

export interface VideoDimensionsIProps {
  width: number
  height: number
}

// Enums
export enum IncidentPlayerError {
  NO_INCIDENT_CLIP = 'No incident clip attached',
  ERROR_FETCHING_INCIDENT_CLIP = 'Error fetching incident clip',
  ERROR_FETCHING_STREAM = 'Error fetching live stream',
  NO_VIDEO_FEED = 'Live stream not available',
}

export enum VideoFeedEvent {
  BeginRequesting,
  Received,
  Playing,
}

export enum VideoControlsAction {
  Play,
  Pause,
  Backward10,
  Forward10,
  Rewind,
  FastForward,
  Fullscreen,
}

export enum VideoFrameActionType {
  CAPTURE_IMAGE = 'CAPTURE_IMAGE',
  SET_REFRESH_TRUE = 'SET_REFRESH_TRUE',
  SET_REFRESH_FALSE = 'SET_REFRESH_FALSE',
  RESET = 'RESET',
}

export interface VideoFrameStateIProps {
  imageUrl: string
  frameDimensions: VideoDimensionsIProps
  refreshing?: boolean
  captureCount?: number
}

export type VideoFrameAction =
  | { type: VideoFrameActionType.CAPTURE_IMAGE; payload: VideoFrameStateIProps }
  | { type: VideoFrameActionType.SET_REFRESH_TRUE }
  | { type: VideoFrameActionType.SET_REFRESH_FALSE }
  | { type: VideoFrameActionType.RESET }
