import { HYDRATE } from 'next-redux-wrapper'

import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'

import { SavedFilterI } from '@/features/incidents-table'
import {
  IncidentFilterCriteria,
  IncidentStatus,
  MutedStatus,
} from '@/graphql/generated/schemas'

import type { AppState } from '../store'
import { buildFilterInput } from './conversion'
import { removeDefaultMutedStatusAll, removeNullValues } from './utils'

export type ExtraIncidentFilterCriteria = {
  nameLike?: string
  ownerId?: string
}

export interface IncidentsStateIProps {
  activeFilter: SavedFilterI | null
  activeFilterCriteria: IncidentFilterCriteria
  extraFilterCriteria: ExtraIncidentFilterCriteria
  pageSize: number
}

export const initialState: IncidentsStateIProps = {
  activeFilter: null,
  activeFilterCriteria: {
    statusIn: [IncidentStatus.Active, IncidentStatus.InProgress],
    mutedStatus: MutedStatus.NotMuted,
  },
  extraFilterCriteria: {
    nameLike: null,
    ownerId: null,
  },
  pageSize: 25,
}

export const incidentsSlice = createSlice({
  name: 'incidents',
  initialState,
  reducers: {
    setActiveFilter: (state, action: PayloadAction<SavedFilterI | null>) => {
      state.activeFilter = action.payload
    },
    setActiveFilterCriteria: (
      state,
      action: PayloadAction<IncidentFilterCriteria>
    ) => {
      state.activeFilterCriteria = action.payload
    },
    setExtraFilterCriteria: (
      state,
      action: PayloadAction<ExtraIncidentFilterCriteria>
    ) => {
      state.extraFilterCriteria = action.payload
    },
    setIncidentsPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload
    },
    setIncidentNameFilter: (state, action: PayloadAction<string | null>) => {
      state.extraFilterCriteria = {
        ...state.extraFilterCriteria,
        ...{ nameLike: action.payload },
      }
    },
    setIncidentOwnerFilter: (state, action: PayloadAction<string | null>) => {
      state.extraFilterCriteria = {
        ...state.extraFilterCriteria,
        ...{ ownerId: action.payload },
      }
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.subject,
      }
    },
  },
})

export const {
  setActiveFilter,
  setIncidentNameFilter,
  setIncidentOwnerFilter,
  setIncidentsPageSize,
  setActiveFilterCriteria,
  setExtraFilterCriteria,
} = incidentsSlice.actions

export const selectActiveFilter = (state: AppState) =>
  state.incidents?.activeFilter

export const selectActiveFilterCriteria = (state: AppState) =>
  state.incidents?.activeFilterCriteria

export const selectExtraFilterCriteria = (state: AppState) =>
  state.incidents?.extraFilterCriteria

export const selectIncidentsPageSize = (state: AppState) =>
  state.incidents?.pageSize

export const selectIncidentOwnerIdFilter = (state: AppState) =>
  state?.incidents?.extraFilterCriteria?.ownerId

export const selectIncidentFilterCount = createSelector(
  selectActiveFilterCriteria,
  selectExtraFilterCriteria,
  (activeFilterCriteria, extraFilterCriteria) => {
    const activeFilterCriteriaFiltered =
      // Remove MutedStatus.All filter from the count, this is a default filter which the user does not apply.
      // This count represents the filter count applied by the user
      removeDefaultMutedStatusAll(activeFilterCriteria)
    const savedFilterCount = Object.keys(
      removeNullValues(activeFilterCriteriaFiltered || {})
    ).length
    const extraFilterCount = extraFilterCriteria?.ownerId ? 1 : 0
    return savedFilterCount + extraFilterCount
  }
)

export const selectIncidentsFilterInput = createSelector(
  selectActiveFilterCriteria,
  selectExtraFilterCriteria,
  (activeFilterCriteria, extraFilterCriteria) =>
    buildFilterInput(activeFilterCriteria, extraFilterCriteria)
)

export const selectIncidentsActiveFilterInput = createSelector(
  selectActiveFilterCriteria,
  (activeFilterCriteria) => buildFilterInput(activeFilterCriteria)
)

// Default export for reducers is a pattern recommended by Redux
// See here: <https://redux.js.org/tutorials/fundamentals/part-3-state-actions-reducers#combinereducers>
// eslint-disable-next-line import/no-default-export
export default incidentsSlice.reducer
