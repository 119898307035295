import { ChangeEvent } from 'react'

import { BiSearch } from 'react-icons/bi'

import {
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputProps,
} from '@chakra-ui/react'

interface SearchInputStylesIProps {
  input?: Partial<InputProps>
  inputGroup?: Partial<InputGroupProps>
}

interface SearchInputIProps extends Omit<InputProps, 'onChange'> {
  placeholder?: string
  value?: string
  showIcon?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  dataTestId?: string
  customStyles?: SearchInputStylesIProps
}

const defaultStyles: SearchInputStylesIProps = {
  input: {
    _placeholder: {
      fontSize: '14px',
    },
    autoCapitalize: 'off',
    autoComplete: 'off',
    autoCorrect: 'off',
    bgColor: 'white',
    borderColor: '#D5DCE4',
    borderWidth: '1px',
    color: '#353849',
    fontWeight: 'medium',
    variant: 'outline',
  },
  inputGroup: {},
}

export const SearchInput = ({
  placeholder,
  value,
  showIcon = true,
  onChange,
  dataTestId,
  customStyles,
  ...restProps
}: SearchInputIProps) => {
  const mergedStyles: SearchInputStylesIProps = {
    input: { ...defaultStyles.input, ...customStyles?.input },
    inputGroup: { ...defaultStyles.inputGroup, ...customStyles?.inputGroup },
  }
  return (
    <InputGroup>
      {showIcon && (
        <InputLeftElement
          children={<BiSearch />}
          fontSize='1.2em'
          pointerEvents='none'
        />
      )}
      <Input
        {...mergedStyles.input}
        {...restProps}
        data-testid={dataTestId}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      />
    </InputGroup>
  )
}
