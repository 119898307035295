import { Table, flexRender } from '@tanstack/react-table'

import {
  type ResponsiveValue,
  TableColumnHeaderProps,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'

export interface TableHeaderIProps<DataT> extends TableColumnHeaderProps {
  table: Table<DataT>
  rowBgColor?: ResponsiveValue<string>
  rowBorderRadius?: ResponsiveValue<
    | number
    | 'none'
    | 'xl'
    | 'sm'
    | 'md'
    | 'lg'
    | '2xl'
    | '3xl'
    | 'full'
    | (string & NonNullable<unknown>)
    | 'base'
    | 'initial'
    | 'inherit'
    | '-moz-initial'
    | 'revert'
    | 'revert-layer'
    | 'unset'
  >
}

export const TableHeader = <DataT,>({
  table,
  rowBgColor,
  rowBorderRadius,
  ...rest
}: TableHeaderIProps<DataT>) => {
  return (
    <Thead>
      {table.getHeaderGroups().map((headerGroup) => (
        <Tr bgColor={rowBgColor} key={headerGroup.id}>
          {headerGroup.headers.map((header, index) => (
            <Th
              borderBottom={rowBorderRadius ? 'none' : undefined}
              borderBottomLeftRadius={index === 0 ? rowBorderRadius : undefined}
              borderBottomRightRadius={
                index === headerGroup.headers.length - 1
                  ? rowBorderRadius
                  : 'initial'
              }
              borderTopLeftRadius={index === 0 ? rowBorderRadius : undefined}
              borderTopRightRadius={
                index === headerGroup.headers.length - 1
                  ? rowBorderRadius
                  : 'initial'
              }
              color='#5F5F5F'
              cursor='pointer'
              fontSize='12px'
              fontWeight={400}
              key={header.id}
              onClick={header.column.getToggleSortingHandler()}
              position='relative'
              py='16px'
              textTransform='capitalize'
              width={header.column.getSize()}
              {...rest}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
            </Th>
          ))}
        </Tr>
      ))}
    </Thead>
  )
}
