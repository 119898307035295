import { useMemo } from 'react'

import { FeatureFlag, useFeatureFlag } from '@/hooks/useFeatureFlags'

import { IncidentsListType, RefetchIncidentsFnType } from '../types/types'
import { filterByFlaggedColumn } from '../utils/helpers'
import { columns } from './Columns'
import { DataTable } from './DataTable'

export const IncidentsTable = ({
  incidents,
  totalIncidents,
  refetch,
}: {
  incidents?: IncidentsListType
  totalIncidents?: number
  refetch?: RefetchIncidentsFnType
}) => {
  const isAiOperatorEnabled = useFeatureFlag(FeatureFlag.aiOperator)
  const tableData = incidents.map((e) => {
    const device = e?.node?.devices[0]
    return {
      id: e?.node?.id,
      isFlagged: e?.node?.isFlagged,
      displayId: e?.node?.displayId,
      facilityName: {
        name: e?.node?.facility?.name,
        shortName: e?.node?.facility?.shortName,
        archived: e?.node?.facility?.archived,
      },
      incidentName: e?.node?.name,
      floor: e?.node?.floor?.name,
      date: e?.node?.createdAt,
      incidentStatusOwner: {
        status: e?.node?.status,
        owner: e?.node?.owner,
      },
      incidentType: e?.node?.type,
      source: device?.type || e?.node?.source,
      operator: e?.node?.owner
        ? `${e?.node?.owner?.firstName} ${e?.node?.owner?.lastName}`
        : null,
      guard: e?.node ? e?.node : null,
    }
  })

  const filteredColumns = useMemo(() => {
    if (!isAiOperatorEnabled) {
      return filterByFlaggedColumn(columns)
    }
    return columns
  }, [isAiOperatorEnabled])

  return tableData ? (
    <DataTable
      columns={filteredColumns}
      data={tableData}
      refetch={refetch}
      totalIncidents={totalIncidents}
    />
  ) : null
}
